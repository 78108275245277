<template>
  <div>
    <div class="main-content main-content-about">
      <div class="container">
        <p class="pc-builder-title">ARMÁ TU PC</p>
        <div class="brands-container">
            <router-link :to="{name: 'PC Builder Intel'}"><div class="intel"></div></router-link>
            <router-link :to="{name: 'PC Builder AMD'}"><div class="amd"></div></router-link>
            <router-view></router-view>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
    computed: {
        ...mapGetters({

        }),
    },
};
</script>

<style scoped>
.pc-builder-title {
    margin-top: 30px;
    top: 175px;
    left: 100px;
    height: 28px;
    text-align: left;
    font: normal normal 800 24px Rubik;
    letter-spacing: 0px;
    color: #030303;
    opacity: 1;
}
.brands-container {
    display: inline-flex;
    justify-content: space-between;
    margin-top: 80px;
    margin-left: 200px;
    margin-right: 200px;
    width: 814px;
    height: 214px;
    opacity: 1;
}
.intel {
    left: 275px;
    width: 360px;
    height: 214px;
    background: transparent url('../../public/img/intel.jpg') 100% 100% no-repeat padding-box;
    border: 1px solid #707070;
    border-radius: 5px;
    opacity: 1;
}
.amd {
    left: 275px;
    width: 360px;
    height: 214px;
    background: transparent url('../../public/img/AMD.jpg') 0% 0% no-repeat padding-box;
    border: 1px solid #707070;
    border-radius: 5px;
    opacity: 1;
}
</style>